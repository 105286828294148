export default [
  {
    text: 'About',
    links: [
      {
        text: 'Mission',
        to: '/about',
      },
      {
        text: 'Committee',
        to: '/about/#committee',
      },
      {
        text: 'Pilot Resources',
        to: '/about/#resources',
      },
      {
        text: 'Committee Login',
        to: '/login',
      },
    ],
  },
  {
    links: [
      {
        text: 'Clubs',
        to: '/clubs',
      },
      {
        text: 'Flight Schools & Instructors',
        to: '/flight-school-instructors',
      },
      {
        text: 'Which Wing?',
        to: '/which-wing',
      },
      {
        text: 'Want to learn?',
        to: '/flight-school-instructors',
      },
    ],
    text: 'Aviation',
  },
  {
    links: [
      {
        text: 'Gallery',
        to: '/gallery/',
      },
      {
        text: 'Contact',
        to: '/contact-us/',
      },
    ],
    text: 'Contact',
  },
]
