import * as React from 'react'
import Card from '../Card'
import CTACard from '../CTACard'
import {
  SocialMedia,
  Footer as StyledFooter,
  FooterLink,
  FooterItem,
  FooterList,
  FooterInfo,
  StyledFlex,
} from './styles'
import { Container, Cards } from '../styles'
import Logo from '../Logo'
import styled from 'styled-components'
// import Instagram from 'react-icons/lib/fa/instagram'
// import Facebook from 'react-icons/lib/fa/facebook'
// import Google from 'react-icons/lib/fa/google-plus'
// import Twitter from 'react-icons/lib/fa/twitter'
import footerLinks from './footer'
import { NavLogo } from '../Navbar/styles'

const CardsContainer = styled(Container)`
  margin-bottom: 50px;
  margin-top: 100px;
  > div {
    justify-content: space-around;
    @media (min-width: 940px) {
      > div {
        width: 31%;
      }
    }
  }
`

const Footer: React.SFC = () => (
  <>
    <StyledFooter>
      <FooterInfo>
        <NavLogo src="/assets/logo.png" />
        <p style={{ marginTop: 10 }}>
          © 2020 QSA Website
          <br />
          All rights reserved.
        </p>
      </FooterInfo>
      <StyledFlex>
        {footerLinks.map((item) => (
          <FooterList key={item.text}>
            <span>{item.text}</span>
            {item.links.map((link) =>
              link.target ? (
                <FooterItem key={link.text} href={link.to} target={link.target}>
                  {link.text}
                </FooterItem>
              ) : (
                <FooterLink key={link.text} to={link.to || '#'}>
                  {link.text}
                </FooterLink>
              )
            )}
          </FooterList>
        ))}
      </StyledFlex>
    </StyledFooter>
  </>
)

export default Footer
